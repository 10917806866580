
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.custom-pricing::v-deep {
    &.full-height,
    .full-height {
        height: 100%;
    }

    .top-border {
        border-top: $faded-blue 6px solid;
    }

    .link {
        display: flex;
        align-items: center;

        a {
            display: block;
            max-width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .form-row {
        > div {
            display: flex;
            align-items: center;
        }
    }

    .v-input--checkbox {
        margin-top: 0;
    }

    .v-text-field {
        max-width: fit-content;
        margin: 0 8px;
    }
}
